export default {
  messages: {
    uk: {
      Готово: 'Готово',
      'Ваше оголошення': 'Ваше оголошення',
      'буде оновлено після оплати': 'буде оновлено протягом 10 хв',
      'буде оновлено': 'буде оновлено протягом 10 хв після оплати',
    },
    ru: {
      Готово: 'Готово',
      'Ваше оголошення': 'Ваше объявление',
      'буде оновлено після оплати': 'будет обновлено в течение 10 мин после оплаты',
      'буде оновлено': 'будет обновлено в течение 10 мин',
    },
  },
};

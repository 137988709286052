import { mapGetters } from 'vuex';
import i18n from './i18n';

export default {
  name: 'UpdatedStatisticsPopup',
  components: {
    Popup: () => import('../../../Common/Popup.vue'),
  },
  computed: {
    ...mapGetters({
      langPrefix: 'lang/prefix',
      popup: 'cabinet/advertisements/popups/popup',
    }),
    info() {
      return this.popup?.info || {};
    },
    url() {
      return `${this.langPrefix}${this.popup?.info?.uri || '/cabinet/'}`;
    },
    postText() {
      return this.info?.isPaid ? this.$t('буде оновлено') : this.$t('буде оновлено після оплати');
    },
  },
  i18n,
};
